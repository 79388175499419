import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FirebaseService } from 'src/app/services/firebase.service';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-menu-admin',
  templateUrl: './menu-admin.component.html',
  styleUrls: ['./menu-admin.component.scss']
})
export class MenuAdminComponent implements OnInit {
  @Input() title: string;

  page: string;

 @Output() activeMenu = new EventEmitter();

 @Output() activeDrop = new EventEmitter();

 public isOpenMenu: boolean = false;

 public dropMenuActive: any;
  constructor(
    public _general: GeneralService,
    public _firebase: FirebaseService,
    @Inject(DOCUMENT)private document:Document
  ) {
    this.title = '';
    this.page = window.location.pathname;
  }

  ngOnInit(): void {
    this.isOpenMenu = this._general.isOpenMenu;
    this.dropMenuActive = this._general.dropMenuActive;
  }

  public openDropMenu(element: any): void {
    if (this.dropMenuActive) {
      this.closeDropMenu();
    } else {
      this.dropMenuActive = element;
      this.activeDrop.emit(element);
    }
  }

  public closeDropMenu(): void {
    this.dropMenuActive = null;
    this.activeDrop.emit(null);
  }

  public closeMenu(): void {
    this.isOpenMenu = false;
    this.activeMenu.emit(false);
  }

  public openMenu(): void {
    this.isOpenMenu = true;
    this.activeMenu.emit(true);
  }

  public signOut(): void {
    this._firebase.signOut().then(() => {
      this._general._spinner.show()
      this._general.openRouter('auth'); 
    });
  }

}
