import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { FirebaseService } from './services/firebase.service';
import { GeneralService } from './services/general.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private _general: GeneralService,
    private _firebase: FirebaseService
  ){}
  
  async canActivate(route: ActivatedRouteSnapshot) {
    this._general._spinner.show();
    const url: string = route.url[0].path;
    const user = await this._firebase.getUser();
    this._general._spinner.hide();
    console.log(user.role);
    if (user) {
      if (user.role === 'admin') {
        if (url == 'auth') {
          this._general.openRouter('admin');
          return false;
        } else {
          return true;
        }
      } else {
        this._firebase.signOut();
        return false;
      }
    } else {
      if (url == 'auth') {
        return true;
      } else {
        this._general.openRouter('auth');
        return false;
      }
    }
  }
  
}
