<nav class="navbar">
  <div>
    <a href="#banner">
      <img
        class="logo"
        src="../../../assets/images/Grupo 59.png"
        alt="logo-nav"
      />
    </a>
  </div>

  <input type="checkbox" id="check" />
  <label for="check" class="bar-btn">
    <i class="fas fa-bars"></i>
  </label>
  <ul class="nav-menu navbar-nav">
    <li class="nav-item">
      <a class="nav-link nav-ul-li-styles" href="#about" (click)="checked()">
        NOSOTROS
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link nav-ul-li-styles" href="#brands" (click)="checked()">
        MARCAS
      </a>
    </li>
    <li class="nav-item">
      <div class="nav-link nav-ul-li-styles submenu-menu" style="font-weight:500">CATÁLOGOS
        <div class="submenu-h">
          <a target="_blank" class="" href="../../../assets/images/Master_Lock_2022.pdf">Master Lock</a>
          <a target="_blank" class="" href="../../../assets/images/Squirk_2022.pdf">Squirk</a>
          <a target="_blank" class="" href="../../../assets/images/JB_Weld_2022.pdf">JB Weld</a>
          <a target="_blank" class="" href="../../../assets/images/Turtle_Wax_2022.pdf">Turtle Wax</a>
        </div>  
      </div>
    </li>
    <li class="nav-item">
      <a class="nav-link nav-ul-li-styles" href="#contact" (click)="checked()">
        CONTACTO
      </a>
    </li>
  </ul>
</nav>
