import { Injectable } from '@angular/core';
import { Newsletter } from '../interfaces/newsletter';
import { FirebaseService } from './firebase.service';
@Injectable({
  providedIn: 'root'
})
export class NewsletterService {
  public newsletter!: any[];
  constructor(private _firebase:FirebaseService) { }

  public async add(newletter: Newsletter): Promise<Newsletter> {
    let res = await this._firebase.addDocument('newsletter', newletter);
    return {
      ...newletter,
      id: res.id,
    };
  }

  public async update(id: string, newletter: Newsletter): Promise<Newsletter> {
    let res = await this._firebase.updateDocument('newsletter', newletter, id);
    return {
      ...newletter,
    };
  }
  public async delete(id: string) {
    let res = await this._firebase.deleteDocument('newsletter', id);
    return {
      message: 'Eliminado correctamente.',
    };
  }

  public async getNewsletter(): Promise<Newsletter[]> {
    if (this.newsletter) {
      return this.newsletter;
    }
    this.newsletter = [];
    try {
      const ref = this._firebase.getCollectionRef('newsletter');
      ref.orderBy('date', 'desc');
      return await this._firebase.generateData(ref, this.newsletter);
    } catch (error) {
      return [];
    }
  }
  public async getById(id:string){
    try{
     return  this._firebase.getDocument('newsletter',id);
    }catch(error){
      return null;
    }
  }
}
