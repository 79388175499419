import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Newsletter } from 'src/app/interfaces/newsletter';
import { FirebaseService } from 'src/app/services/firebase.service';
import { GeneralService } from 'src/app/services/general.service';
import { NewsletterService } from 'src/app/services/newsletter.service';
import { SweetAlertIcon } from 'sweetalert2';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  miFormulario: FormGroup = new FormGroup({
    email: new FormControl('',[Validators.required, Validators.email])
  })
  constructor(private _general:GeneralService, private _firebase:FirebaseService, private _newsletters:NewsletterService, private fb: FormBuilder) { }

  ngOnInit(): void {
  }
  public async saveData():Promise<void>{
   try {
    if(!this.miFormulario.valid){
      this.alert('warning', `Escribe tu correo electronico correctamente`, '');
      return
    }
      this._general._spinner.show();
      const date = new Date().getTime();
      const contact: Newsletter = {
        email: this.miFormulario.value.email,
        date: date,
      };
      await this._newsletters.add(contact);
      this._general._spinner.hide();
      this.alert('success', `Enviado correctamente`, '');
      this.miFormulario.reset();
    } catch (error) {
      this._general._spinner.hide();
      this.alert('error', `Error al guardar`, '');
    }
  }

  private alert(type: SweetAlertIcon, title: string, text: string): void {
    this._general.swalTimer('center', type, title, text, 0, true, false);
  }

}
