<header *ngIf="_firebase.user" [ngClass]="{ 'header-push': isOpenMenu }">

</header>
<nav [ngClass]="isOpenMenu ? 'nav-active': 'nav-active-border'">
  <div class="container-image">
    <img class="img" src="../../../assets/images/Grupo 59.png" alt="logotipo  MKTMTY" />
    <i *ngIf="isOpenMenu" class="fas fa-times" (click)="closeMenu()"></i>
    <i *ngIf="!isOpenMenu" class="icon-close fas fa-bars" (click)="openMenu()"></i>
  </div>
  <ul>
    <!-- <li
        [ngClass]="{ active: page === '/admin' }"
        (click)="_general.openRouter('admin')"
      >
        <div class="icon-flex">
          <i class="fa-solid fa-chart-line"></i>
          <p>dashboard</p>
        </div>
      </li> -->
    <li [ngClass]="{ active: page === '/admin/contact' }" (click)="_general.openRouter('admin/contact')">
      <div class="icon-flex">
        <i class="fa-solid fa-cart-flatbed"></i>
        <p>Contactos</p>
      </div>
    </li>
    <li [ngClass]="{ active: page === '/admin/suscribes' }" (click)="_general.openRouter('admin/suscribes')">
      <div class="icon-flex">
        <i class="fa-brands fa-buromobelexperte"></i>
        <p>Subscripciones</p>
      </div>
    </li>
    <li [ngClass]="{ active: page === '/admin/banner' }" (click)="_general.openRouter('admin/banner')">
      <div class="icon-flex">
        <i class="fa-brands fa-buromobelexperte"></i>
        <p>Banner</p>
      </div>
    </li>
  </ul>
  <ul>
    <li class="signout" (click)="signOut()">Cerrar sesión</li>
  </ul>
</nav>
