<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-clip-rotate-pulse"
  [fullScreen]="true"
>
  <p class="t-bold text-white">Cargando...</p>
</ngx-spinner> 
<router-outlet></router-outlet>
