<footer>
  <section class="logo">
    <img src="../../../assets/images/Grupo 59.png" alt="" />
  </section>
  <section class="footer">
    <article class="suscribe">
      <p class="title-p">Suscríbete</p>
      <p class="description">
        Recibe las noticias más relevantes y promociones únicas en tu correo
        electrónico
      </p>
      <div>
        <form (ngSubmit)="saveData()" autocomplete="off" [formGroup]="miFormulario">
              <input type="text" placeholder="Correo electrónico" formControlName="email" />
              <button type="submit"><img src="../../../assets/images/icons/right-arrow.png" alt=""></button>
        </form>
      </div>
      <span class="error" *ngIf="(this.miFormulario.get('email')?.errors?.['required'] && miFormulario.touched) ">Correo obligatorio</span>
      <span class="error" *ngIf="this.miFormulario.get('email')?.errors?.['email']">Correo con formato incorrecto</span>
    </article>
    <article class="menu">
      <p>Nosotros</p>
      <ul>
        <li><a href="#brands">Nuestras marcas</a></li>
        <li><a href="#about">Nosotros</a></li>
        <li><a href="#contact">Contáctanos</a></li>
      </ul>
    </article>
  </section>
  <section class="foot">
    <p>
      Marketing de Monterrey ® 2022 - Todos los derechos reservados. Aviso de
      privacidad - Términos y condiciones
    </p>
  </section>
</footer>
