import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal, { SweetAlertIcon, SweetAlertPosition } from 'sweetalert2';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class GeneralService {
  public isOpenMenu: boolean = true;
  public dropMenuActive: any;
  constructor(
    public _spinner: NgxSpinnerService,
    public _http: HttpClient,
    public _router: Router
  ) { }

  public swalTimer(
    position: SweetAlertPosition,
    icon: SweetAlertIcon,
    title: string,
    text: string,
    timer: number,
    showConfirmButton: boolean,
    showCancelButton: boolean
  ) {
    return Swal.fire({
      position: position,
      icon: icon,
      title: title,
      text: text,
      showConfirmButton: showConfirmButton,
      showCancelButton: showCancelButton,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      timer: timer,
    });
  }

  public getJsonData(route: any): Promise<any> {
    return new Promise((resolve) => {
      this._http.get('../../assets/' + route + '.json').subscribe((data) => {
        resolve(data);
      });
    });
  }

  public openRouter(router: any): void {
    this._router.navigate([router]);
  }

  public alertSuccess(title: string, text: string) {
    return this.swalTimer('center', 'success', title, text, 0, true, false);
  }

  public alertQuestion(title: string, text: string) {
    return this.swalTimer('center', 'question', title, text, 0, true, true);
  }

  public alertError(title: string, text: string) {
    return this.swalTimer('center', 'error', title, text, 0, true, false);
  }

  public alertWarning(title: string, text: string) {
    return this.swalTimer('center', 'warning', title, text, 0, true, false);
  }

  public changeAttribute(value: any, ...args: any): any {
    if (value && args[0] && args[1] && args[2]) {
      let data = args[2].find((v: any) => {
        if (v[args[0]]) {
          return v[args[0]] == value;
        } else {
          return false;
        }
      });
      if (data) {
        if (data[args[1]]) {
          return data[args[1]];
        } else {
          return value;
        }
      } else {
        return value;
      }
    }
    return value;
  }

  public getDate(timestamp: any): void {
    const newDate = new Date(timestamp.seconds * 1000);
    const day = newDate.getDate();
    const month = newDate.getMonth();
    const year = newDate.getFullYear();
    const fullDate: any = `${day}/${month < 10 ? 0 : ''}${month + 1}/${year}`;
    return fullDate;
  }
}
