import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

  constructor(public router:Router) { }

  ngOnInit(): void {
  }
  
  public checked() {
    if (screen.width < 670) {
      const input = document.getElementById('check');
      input?.click();
      
    }
  }

 

   
  
 
}
