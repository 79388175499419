// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig : {
    apiKey: "AIzaSyAEA8oVdjy5d7isRXFa3iSVg2ENjjnYhag",
  authDomain: "mktmty-673cb.firebaseapp.com",
  projectId: "mktmty-673cb",
  storageBucket: "mktmty-673cb.appspot.com",
  messagingSenderId: "918476587957",
  appId: "1:918476587957:web:97d2376fa7800a99504ffc",
  measurementId: "G-S831GE8EH7"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
